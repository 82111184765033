import React from 'react'
import styled from 'styled-components'
import { SvgLogo } from 'components/svgs/svg-logo'
import { SvgLogoLarge } from 'components/svgs/svg-logo-large'
import { NextLink } from 'components/next-link/next-link'
import { useLocationContext } from 'providers/location-provider/location-provider'

type LogoProps = {
	suffix: string
	large?: boolean
}

export const LogoLink = styled(NextLink)`
	position: relative;
	padding: 5px 6px;
`

const LogoComponent: React.FC<LogoProps> = ({ large, suffix, ...props }) => {
	const { language } = useLocationContext()
	const href = `/${language}/`

	return (
		<LogoLink href={href} {...props}>
			<>{large ? <SvgLogoLarge suffix={suffix} /> : <SvgLogo suffix={suffix} />}</>
		</LogoLink>
	)
}

export const Logo = React.memo(LogoComponent)
