import React from 'react'

export const SvgSunCloud: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle: string }
> = ({ suffix, customTitle, ...props }) => {
	const idPrefix = 'svg-sun-cloud' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle}</title>
			<defs>
				<linearGradient id="svg-sun-cloud-a" x1="124.776%" x2="37.129%" y1="107.53%" y2="37.243%">
					<stop offset="0%" stopColor="#FFBE00" />
					<stop offset="100%" stopColor="#FFB700" />
				</linearGradient>
				<linearGradient id="svg-sun-cloud-b" x1="-30.939%" x2="139.74%" y1="2.487%" y2="93.773%">
					<stop offset="0%" stopColor="#FFF" />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.8} />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd" transform="translate(2 3)">
				<circle cx={14.444} cy={5.556} r={5.556} fill="url(#svg-sun-cloud-a)" />
				<path
					fill="url(#svg-sun-cloud-b)"
					fillRule="nonzero"
					d="M19.936 9.654c-.043-2.368-1.942-4.288-4.31-4.331A6.525 6.525 0 009.162.033a6.54 6.54 0 00-6.528 5.653A4.428 4.428 0 00.373 11.51a4.422 4.422 0 004.053 2.645h11.2c2.432-.064 4.374-2.07 4.31-4.501z"
					transform="translate(0 3.333)"
				/>
			</g>
		</svg>
	)
}
