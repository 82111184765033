'use client'

import { useEffect, useState } from 'react'
import { isLinkActive } from 'utils/router'
import { usePathname } from 'next/navigation'
import { removeTrailingSlash } from 'utils/url'

export const useActiveLink = (href: string, isExact = false) => {
	const pathname = usePathname()
	const path = removeTrailingSlash(pathname?.split('?')[0] || '')
	const url = removeTrailingSlash(href.split('?')[0])

	const [isActive, setIsActive] = useState(() => isLinkActive(path, url, isExact))

	useEffect(() => {
		setIsActive(isLinkActive(path, url, isExact))
	}, [path, url, isExact])

	return isActive
}
