import React from 'react'
import styled from 'styled-components'

const SvgYouTubePath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgYouTube: React.FC<JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }> = ({
	suffix,
	...props
}) => {
	const idPrefix = 'svg-youtube' + suffix
	return (
		<svg width={24} height={24} aria-labelledby={`${idPrefix}-title`} role="img" fill="none" {...props}>
			<title id={`${idPrefix}-title`}>YouTube</title>
			<g clipPath={`url(#${idPrefix}-clip0)`}>
				<SvgYouTubePath d="M12.154 3.999c1.083.003 7.518.041 9.236.489 1.028.264 1.839 1.035 2.115 2.014.515 1.789.495 5.516.495 5.516v.093c-.004.586-.046 3.783-.494 5.404-.277.979-1.088 1.75-2.116 2.014-1.798.451-8.762.47-9.351.471h-.05c-.306 0-7.523-.01-9.38-.49-1.03-.263-1.84-1.035-2.116-2.014-.448-1.604-.49-4.815-.494-5.403v-.187c.004-.585.046-3.783.494-5.404.276-.979 1.107-1.77 2.115-2.033 1.718-.43 8.153-.467 9.236-.47h.31zM9.607 8.573v6.852L15.854 12 9.607 8.573z" />
			</g>
			<defs>
				<clipPath id={`${idPrefix}-clip0`}>
					<SvgYouTubePath d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>
	)
}
