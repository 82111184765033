import React from 'react'

export const SvgThunderstormAndShowers: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle: string }
> = ({ suffix, customTitle, ...props }) => {
	const idPrefix = 'svg-thunderstorm-and-showers' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle}</title>
			<defs>
				<linearGradient id="svg-thunderstorm-and-showers-a" x1="-30.939%" x2="139.74%" y1="2.487%" y2="93.773%">
					<stop offset="0%" stopColor="#FFF" />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.6} />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
				</linearGradient>
				<linearGradient id="svg-thunderstorm-and-showers-b" x1="91.261%" x2="36.502%" y1="142.148%" y2="0%">
					<stop offset="0%" stopColor="#FF6328" />
					<stop offset="100%" stopColor="#FFB700" />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd">
				<path
					fill="url(#svg-thunderstorm-and-showers-a)"
					fillRule="nonzero"
					d="M19.936 9.654c-.043-2.368-1.942-4.288-4.31-4.331A6.525 6.525 0 009.162.033a6.54 6.54 0 00-6.528 5.653A4.428 4.428 0 00.373 11.51a4.422 4.422 0 004.053 2.645h11.2c2.432-.064 4.374-2.07 4.31-4.501z"
					transform="translate(2 2)"
				/>
				<path
					fill="url(#svg-thunderstorm-and-showers-b)"
					d="M2.725 11.479l4.688-6.283a.433.433 0 00.035-.466.471.471 0 00-.417-.243h-2.24L5.616.536a.445.445 0 00-.273-.5.487.487 0 00-.569.151L.087 6.47a.433.433 0 00-.035.466c.08.15.24.243.417.243h2.24l-.825 3.95a.445.445 0 00.273.5.487.487 0 00.568-.15z"
					transform="translate(13.111 10.889)"
				/>
				<path
					d="M6.512 18.044c-.434-.434-1.102-.47-1.493-.079l-.943.943c-.39.39-.355 1.059.079 1.493.434.434 1.102.469 1.493.078l.942-.943c.39-.39.356-1.058-.078-1.492zm4.444 1.111c-.434-.434-1.102-.47-1.492-.079l-.943.943c-.39.39-.356 1.059.078 1.493.434.434 1.103.469 1.493.078l.943-.942c.39-.39.355-1.06-.079-1.493z"
					fill="#5A89FF"
					fillRule="nonzero"
				/>
			</g>
		</svg>
	)
}
