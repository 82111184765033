import React from 'react'
import styled from 'styled-components'
import { mediaBreakpointUpLg } from 'theme/breakpoints'

import { SvgCloudFrost } from 'components/svgs/weather/svg-cloud-frost'
import { SvgHeavyShowers } from 'components/svgs/weather/svg-heavy-showers'
import { SvgLightShowers } from 'components/svgs/weather/svg-light-showers'
import { SvgSnow } from 'components/svgs/weather/svg-snow'
import { SvgThunderstorm } from 'components/svgs/weather/svg-thunderstorm'
import { SvgThunderstormAndShowers } from 'components/svgs/weather/svg-thunderstorm-and-showers'
import { SvgHail } from 'components/svgs/weather/svg-hail'
import { SvgFog } from 'components/svgs/weather/svg-fog'
import { SvgShowers } from 'components/svgs/weather/svg-showers'
import { SvgCloudy } from 'components/svgs/weather/svg-cloudy'
import { SvgSunCloud } from 'components/svgs/weather/svg-sun-cloud'
import { SvgSun } from 'components/svgs/weather/svg-sun'

// Associate similar weather conditions based on the IPMA API to one icon
const sunIds = [1]
const sunCloudIds = [2, 3]
const cloudyIds = [4, 5]
const showersIds = [6, 9, 12]
const lightShowersIds = [7, 10, 13, 15]
const heavyShowersIds = [8, 11, 14]
const cloudFrostIds = [16, 17]
const snowIds = [18]
const thunderstormIds = [19]
const thunderstormAndShowersIds = [20, 23]
const hailIds = [21, 22]
const fogIds = [24, 25, 26, 27]

// Return the weather icon based on id from the IPMA API
const weatherIcon = (suffix: string, id: number, title: string) => {
	if (sunIds.includes(id)) return <SvgSun suffix={suffix} customTitle={title} />
	else if (sunCloudIds.includes(id)) return <SvgSunCloud suffix={suffix} customTitle={title} />
	else if (cloudyIds.includes(id)) return <SvgCloudy suffix={suffix} customTitle={title} />
	else if (showersIds.includes(id)) return <SvgShowers suffix={suffix} customTitle={title} />
	else if (lightShowersIds.includes(id)) return <SvgLightShowers suffix={suffix} customTitle={title} />
	else if (heavyShowersIds.includes(id)) return <SvgHeavyShowers suffix={suffix} customTitle={title} />
	else if (cloudFrostIds.includes(id)) return <SvgCloudFrost suffix={suffix} customTitle={title} />
	else if (snowIds.includes(id)) return <SvgSnow suffix={suffix} customTitle={title} />
	else if (thunderstormIds.includes(id)) return <SvgThunderstorm suffix={suffix} customTitle={title} />
	else if (thunderstormAndShowersIds.includes(id))
		return <SvgThunderstormAndShowers suffix={suffix} customTitle={title} />
	else if (hailIds.includes(id)) return <SvgHail suffix={suffix} customTitle={title} />
	else if (fogIds.includes(id)) return <SvgFog suffix={suffix} customTitle={title} />
}

export type WeatherProps = {
	suffix: string
	typeId: number
	degrees: string
	title: string
}

export const WeatherWidget = styled.div`
	display: flex;
	align-items: center;

	svg {
		width: 20px;
		height: 20px;
		margin-right: 4px;

		${mediaBreakpointUpLg} {
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}
	}

	span {
		position: relative;
		top: 2px;
		margin: 0;
		font-family: ${props => props.theme.fonts.primary};
		font-size: 14px;
		font-weight: 500;
		line-height: 1;
		color: ${props => props.theme.text.primary};

		${mediaBreakpointUpLg} {
			font-size: 16px;
		}
	}
`

const WeatherComponent = ({ suffix, typeId, degrees, title, ...props }: WeatherProps) => {
	return (
		<WeatherWidget {...props}>
			{weatherIcon(suffix, typeId, title)}
			<span>{Math.round(parseFloat(degrees))}ºC</span>
		</WeatherWidget>
	)
}

export const Weather = React.memo(WeatherComponent)
