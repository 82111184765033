export const splitArrayIntoColumns = <T extends Record<string, unknown>>(
	originalArray: T[],
	columns: number,
	breakOnProperty = ''
) => {
	const localArray = [...originalArray]
	const totalItems = localArray.length
	const columnItemsSize = Math.ceil(totalItems / columns)
	const columnSizes = new Array<number>(columns).fill(columnItemsSize)
	return columnSizes.map((columnSize, index) => {
		const isLastColumn = index === columnSizes.length - 1
		const canBreakOnProperty = !!breakOnProperty && !isLastColumn
		const start = index * columnSize
		const end = start + columnSize
		if (canBreakOnProperty && localArray[end - 1].hasOwnProperty(breakOnProperty)) {
			// @ts-ignore
			localArray.splice(end - 1, 0, null)
		}
		return localArray.slice(start, isLastColumn ? undefined : end).filter(item => item !== null)
	})
}
