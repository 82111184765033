import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NextLink } from 'components/next-link/next-link'
import { useActiveLink } from 'hooks/use-active-link'
import { transition } from 'theme/utils'

type ActiveLinkProps = {
	href: string
	children: PropTypes.ReactNodeLike
	title: string
}
type LinkProps = {
	active?: boolean
}

const LinkAnchor = styled(NextLink)<LinkProps>`
	font-weight: ${props => (props.active ? 700 : 500)};
	color: ${props => props.theme.text.primary};
	transition: ${transition('color')};
`

export const ActiveLink = ({ children, href, ...props }: ActiveLinkProps) => {
	const isActive = useActiveLink(href)

	return (
		<LinkAnchor href={href} active={isActive ? true : undefined} {...props}>
			<>{children}</>
		</LinkAnchor>
	)
}

ActiveLink.propTypes = {
	href: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
}
