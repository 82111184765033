import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LoadingCircle } from 'components/loading-circle/loading-circle'
import { transition } from 'theme/utils'

type SecondaryButtonProps = {
	type?: 'button' | 'submit' | 'reset'
	form?: string
	value?: string
	loading?: boolean
	disabled?: boolean
	children: PropTypes.ReactNodeLike
	isLowercase?: boolean
}

const SecondaryButtonLoading = styled(LoadingCircle)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const SecondaryButtonWrapper = styled.button<{ $isLowercase: boolean }>`
	position: relative;
	padding: 14px 48px 10px;
	font-family: ${props => props.theme.fonts.primary};
	font-size: 16px;
	font-weight: 700;
	line-height: 1;
	color: ${props => props.theme.button.secondaryColor};
	text-align: center;
	text-transform: ${props => (props.$isLowercase ? 'none' : 'uppercase')};
	letter-spacing: 0.5px;
	background-color: ${props => props.theme.button.secondaryBg};
	border: none;
	border-radius: 2px;
	outline: none;
	transition: ${transition('background-color')};

	&:hover,
	&:focus,
	&:active {
		background-color: ${props => props.theme.button.secondaryBgHover};
		outline: none;
	}

	&:disabled {
		color: transparent;
		background-color: ${props => props.theme.button.secondaryBgDisabled};
	}
`

export const SecondaryButton = ({
	loading,
	disabled,
	children,
	isLowercase = false,
	...props
}: SecondaryButtonProps) => {
	return (
		<SecondaryButtonWrapper $isLowercase={isLowercase} disabled={loading || disabled} {...props}>
			{children}
			{loading && <SecondaryButtonLoading />}
		</SecondaryButtonWrapper>
	)
}
