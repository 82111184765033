import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { ActiveLink } from 'components/active-link/active-link'
import { darkTheme } from 'theme/theme'
import { rgbaWithHex } from 'theme/utils'
import { mediaBreakpointUpXl } from 'theme/breakpoints'

export type LinkItem = {
	href: string
	label: string
}

export type PrimaryNavigationProps = {
	links: LinkItem[]
}

const PrimaryNavigationNav = styled.nav`
	display: inline-block;
`

const PrimaryNavigationNavList = styled.ul`
	display: flex;
	padding: 0;
	margin: 0;
	list-style: none;
`

const PrimaryNavigationNavItem = styled.li`
	display: inline-block;
	margin-left: 7px;
	font-size: 0;

	${mediaBreakpointUpXl} {
		margin-left: 14px;
	}

	&:first-child {
		margin-left: 0;
	}

	a {
		display: inline-block;
		padding: 10px 8px 6px;
		font-size: 16px;
		line-height: 19px;
		color: ${props => props.theme.text.primary};
		text-align: center;
		text-decoration: none;

		&::after {
			display: block;
			height: 0;
			overflow: hidden;
			font-weight: bold;
			visibility: hidden;
			content: attr(title);
		}

		&:hover,
		&:focus,
		&:active {
			${props => rgbaWithHex(props.theme.text.primary, 0.65)}
			text-decoration: none;
		}
	}
`

export const navigationListItem = (item: LinkItem, index: number) => (
	<PrimaryNavigationNavItem key={`${item.label}-${index}`}>
		<ActiveLink href={item.href} title={item.label}>
			{item.label}
		</ActiveLink>
	</PrimaryNavigationNavItem>
)

const PrimaryNavigationComponent = ({ links }: PrimaryNavigationProps) => (
	<ThemeProvider theme={darkTheme}>
		<PrimaryNavigationNav>
			<PrimaryNavigationNavList>{links.map(navigationListItem)}</PrimaryNavigationNavList>
		</PrimaryNavigationNav>
	</ThemeProvider>
)

export const PrimaryNavigation = React.memo(PrimaryNavigationComponent)
