import React from 'react'
import styled from 'styled-components'

const SvgTwitterPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgTwitter: React.FC<JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }> = ({
	suffix,
	...props
}) => {
	const idPrefix = 'svg-twitter' + suffix
	return (
		<svg width={24} height={24} aria-labelledby={`${idPrefix}-title`} role="img" fill="none" {...props}>
			<title id={`${idPrefix}-title`}>Twitter</title>
			<g clipPath={`url(#${idPrefix}-clip0)`}>
				<SvgTwitterPath d="M24 5.25a10.47 10.47 0 01-2.835.756 4.792 4.792 0 002.165-2.65 9.991 9.991 0 01-3.12 1.161A4.992 4.992 0 0016.614 3C13.893 3 11.7 5.154 11.7 7.795c0 .38.033.746.114 1.094A14.05 14.05 0 011.67 3.874a4.733 4.733 0 00-.673 2.423c0 1.66.877 3.132 2.185 3.984a4.958 4.958 0 01-2.223-.59v.053c0 2.33 1.706 4.264 3.942 4.71-.4.107-.837.158-1.29.158a4.49 4.49 0 01-.932-.082c.638 1.899 2.447 3.294 4.598 3.34a10.053 10.053 0 01-6.1 2.044c-.404 0-.791-.017-1.178-.065C2.18 21.219 4.763 22 7.548 22c9.054 0 14.004-7.308 14.004-13.642 0-.212-.008-.417-.018-.62A9.68 9.68 0 0024 5.25z" />
			</g>
			<defs>
				<clipPath id={`${idPrefix}-clip0`}>
					<SvgTwitterPath d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>
	)
}
