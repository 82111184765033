import React from 'react'
import styled from 'styled-components'
import { useLocationContext } from 'providers/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Menu',
	[Language.En]: 'Menu',
}

const SvgHamburgerPath = styled.path`
	stroke: ${props => props.theme.color.primary};
`

export const SvgHamburger: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-hamburger' + suffix

	return (
		<svg width={24} height={24} fill="none" aria-labelledby={`${idPrefix}-title`} role="img" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<SvgHamburgerPath
				d="M3 18.5h18M3 5.54h18H3zm0 6.48h18H3z"
				strokeWidth={1.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
