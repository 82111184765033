import React from 'react'

export const SvgHeavyShowers: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle: string }
> = ({ suffix, customTitle, ...props }) => {
	const idPrefix = 'svg-heavy-showers' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle}</title>
			<defs>
				<linearGradient id="svg-heavy-showers-a" x1="-30.939%" x2="139.74%" y1="2.487%" y2="93.773%">
					<stop offset="0%" stopColor="#FFF" />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
				</linearGradient>
			</defs>
			<g fill="none">
				<path
					fill="url(#svg-heavy-showers-a)"
					d="M19.936 9.654c-.043-2.368-1.942-4.288-4.31-4.331A6.525 6.525 0 009.162.033a6.54 6.54 0 00-6.528 5.653A4.428 4.428 0 00.373 11.51a4.422 4.422 0 004.053 2.645h11.2c2.432-.064 4.374-2.07 4.31-4.501z"
					transform="translate(2 2)"
				/>
				<path
					d="M6.512 18.044c-.434-.434-1.102-.47-1.493-.079l-.943.943c-.39.39-.355 1.059.079 1.493.434.434 1.102.469 1.493.078l.942-.943c.39-.39.356-1.058-.078-1.492zm8.888 0c-.433-.434-1.102-.47-1.492-.079l-.943.943c-.39.39-.355 1.059.079 1.493.434.434 1.102.469 1.492.078l.943-.943c.39-.39.356-1.058-.078-1.492zm-4.444 1.111c-.434-.434-1.102-.47-1.492-.079l-.943.943c-.39.39-.356 1.059.078 1.493.434.434 1.103.469 1.493.078l.943-.942c.39-.39.355-1.06-.079-1.493zm8.889 0c-.434-.434-1.102-.47-1.493-.079l-.942.943c-.39.39-.356 1.059.078 1.493.434.434 1.102.469 1.493.078l.943-.942c.39-.39.355-1.06-.079-1.493z"
					fill="#5681E9"
				/>
			</g>
		</svg>
	)
}
