import React from 'react'

export const SvgCloudy: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle: string }
> = ({ suffix, customTitle, ...props }) => {
	const idPrefix = 'svg-cloudy' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle}</title>
			<defs>
				<linearGradient id="svg-cloudy-a" x1="-30.939%" x2="139.74%" y1="2.487%" y2="93.773%">
					<stop offset="0%" stopColor="#FFF" />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
				</linearGradient>
				<linearGradient id="svg-cloudy-b" x1="-30.939%" x2="139.74%" y1="2.487%" y2="93.773%">
					<stop offset="0%" stopColor="#FFF" />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.8} />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
				</linearGradient>
			</defs>
			<g fill="none">
				<path
					fill="url(#svg-cloudy-a)"
					d="M14.354 6.95a3.168 3.168 0 00-3.103-3.117A4.698 4.698 0 006.597.023a4.71 4.71 0 00-4.7 4.07 3.184 3.184 0 101.29 6.098h8.064c1.751-.045 3.149-1.49 3.103-3.24z"
					opacity={0.8}
					transform="translate(9 4)"
				/>
				<path
					fill="url(#svg-cloudy-b)"
					d="M20.505 9.93c-.044-2.436-1.996-4.411-4.432-4.455A6.711 6.711 0 009.424.033 6.727 6.727 0 002.71 5.848a4.555 4.555 0 00-2.326 5.99 4.548 4.548 0 004.169 2.721h11.52c2.501-.065 4.498-2.128 4.432-4.63z"
					transform="translate(1 5.143)"
				/>
			</g>
		</svg>
	)
}
