import { removeTrailingSlash } from './url'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'

export const isLinkActive = (path: string, url: string, isExact: boolean) => {
	return isExact ? path === url : path.startsWith(url)
}

type Query = {
	searchQuery?: string
	startDate?: string
	page?: number
	before?: number
}

export const pushNewRoute = (router: AppRouterInstance, pathname: string, query: Query) => {
	const url = new URL(removeTrailingSlash(pathname), window.location.origin)
	query.searchQuery && url.searchParams.set('searchQuery', query.searchQuery)
	query.startDate && url.searchParams.set('startDate', query.startDate)
	query.page && url.searchParams.set('page', query.page.toString())
	query.before && url.searchParams.set('before', query.before.toString())

	router.push(url.toString())

	if (typeof window !== 'undefined') {
		window.scrollTo(0, 0)
	}
}
