import React from 'react'

export const SvgCloudFrost: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle: string }
> = ({ suffix, customTitle, ...props }) => {
	const idPrefix = 'svg-cloud-frost' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle}</title>
			<defs>
				<linearGradient id="svg-cloud-frost-a" x1="-30.939%" x2="139.74%" y1="2.487%" y2="93.773%">
					<stop offset="0%" stopColor="#FFF" />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
				</linearGradient>
			</defs>
			<g fill="none">
				<path
					fill="url(#svg-cloud-frost-a)"
					d="M18.886 9.146c-.04-2.244-1.839-4.063-4.082-4.103A6.181 6.181 0 008.68.03a6.196 6.196 0 00-6.184 5.355 4.195 4.195 0 00-2.143 5.518 4.189 4.189 0 003.84 2.506h10.61c2.305-.06 4.144-1.96 4.083-4.264z"
					transform="translate(2 8.368)"
				/>
				<path
					fill="#FFF"
					stroke="#FFF"
					strokeWidth={0.5}
					d="M21.934 6.691a.493.493 0 00-.674-.18l-1.398.806V5.704a.494.494 0 10-.987 0v1.613l-1.397-.806a.493.493 0 10-.494.855l1.397.806-1.397.807a.493.493 0 10.494.855l1.397-.807v1.614a.494.494 0 10.987 0V9.027l1.397.807a.493.493 0 00.494-.855l-1.397-.807 1.397-.806a.494.494 0 00.18-.675zm-7.369-4.21a.493.493 0 00-.674-.18l-1.397.806V1.494a.494.494 0 10-.987 0v1.613L10.109 2.3a.493.493 0 10-.493.855l1.397.807-1.397.807a.493.493 0 10.493.855l1.397-.807V6.43a.494.494 0 10.988 0V4.817l1.397.807a.493.493 0 00.494-.855l-1.398-.807 1.397-.807a.494.494 0 00.181-.674z"
				/>
			</g>
		</svg>
	)
}
