import React from 'react'
import styled from 'styled-components'

const SvgInstagramPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgInstagram: React.FC<JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }> = ({
	suffix,
	...props
}) => {
	const idPrefix = 'svg-instagram' + suffix
	return (
		<svg width={24} height={24} aria-labelledby={`${idPrefix}-title`} role="img" fill="none" {...props}>
			<title id={`${idPrefix}-title`}>Instagram</title>
			<g clipPath={`url(#${idPrefix}-clip0)`}>
				<SvgInstagramPath d="M16.5 0A7.5 7.5 0 0124 7.5v9a7.5 7.5 0 01-7.5 7.5h-9A7.5 7.5 0 010 16.5v-9A7.5 7.5 0 017.5 0h9zm0 2.25h-9A5.256 5.256 0 002.25 7.5v9a5.256 5.256 0 005.25 5.25h9a5.256 5.256 0 005.25-5.25v-9a5.256 5.256 0 00-5.25-5.25zM12 6a6 6 0 110 12 6 6 0 010-12zm0 2.25A3.754 3.754 0 008.25 12 3.755 3.755 0 0012 15.75 3.755 3.755 0 0015.75 12 3.754 3.754 0 0012 8.25zm6.45-3.5a.8.8 0 110 1.6.8.8 0 010-1.6z" />
			</g>
			<defs>
				<clipPath id={`${idPrefix}-clip0`}>
					<SvgInstagramPath d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>
	)
}
