import React from 'react'
import styled from 'styled-components'
import { useLocationContext } from 'providers/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Porto.',
	[Language.En]: 'Porto.',
}

const SvgLogoPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgLogo: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-logo' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={75} height={23} {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<SvgLogoPath
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.118 5.168v5.596h1.437c2.094 0 3.65-.718 3.65-2.874 0-2.124-1.556-2.722-3.65-2.722H5.118zm0 16.49H0V.707h7.571c5.24 0 7.904 3.232 7.904 7.182 0 3.952-2.664 7.333-7.904 7.333H5.118v6.436zm15.145-6.974c0 1.706 1.106 2.994 2.694 2.994 1.555 0 2.693-1.288 2.693-2.994 0-1.735-1.138-2.963-2.693-2.963-1.588 0-2.694 1.228-2.694 2.963zm-4.788 0c0-4.25 3.321-7.303 7.482-7.303 4.16 0 7.48 3.054 7.48 7.303 0 4.25-3.32 7.333-7.48 7.333s-7.482-3.083-7.482-7.333zM40.89 11.7h-1.736c-2.067 0-2.606 1.788-2.606 3.554v6.403H31.76V7.741h4.608v2.214C36.656 8.37 37.673 7.69 38.6 7.69c.358 0 2.29.051 2.29.051V11.7zm8.27-8.927V7.74h2.785v3.923H49.16v5.055c0 .81.688.929 1.228.929.897 0 1.557-.09 1.557-.09v4.099s-2.245.18-3.415.18c-2.244 0-4.16-.628-4.16-3.5V11.67h-2.063V7.74h2.064V2.773h4.789zm8.583 11.911c0 1.706 1.107 2.994 2.694 2.994 1.556 0 2.693-1.288 2.693-2.994 0-1.735-1.137-2.963-2.693-2.963-1.587 0-2.694 1.228-2.694 2.963zm-4.789 0c0-4.25 3.322-7.303 7.483-7.303 4.16 0 7.481 3.054 7.481 7.303 0 4.25-3.322 7.333-7.481 7.333-4.161 0-7.483-3.083-7.483-7.333zm18.738 1.318a3.012 3.012 0 013.022 3.022c0 1.646-1.344 2.993-3.022 2.993a3.002 3.002 0 01-2.992-2.993c0-1.676 1.348-3.022 2.992-3.022z"
			/>
		</svg>
	)
}
