import React from 'react'

export const SvgSnow: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle: string }
> = ({ suffix, customTitle, ...props }) => {
	const idPrefix = 'svg-snow' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle}</title>
			<path
				fill="#FFF"
				d="M20.931 16.46L18.7 15.17l1.935-1.14a.75.75 0 10-.762-1.292l-2.665 1.57-4.018-2.319 4.017-2.32 2.666 1.572a.75.75 0 00.762-1.292L18.7 8.808l2.232-1.29a.75.75 0 00-.75-1.298L17.95 7.509l-.02-2.246a.75.75 0 10-1.5.013l.028 3.094-4.018 2.32V6.051l2.694-1.522a.75.75 0 00-.738-1.306L12.44 4.328V1.75a.75.75 0 00-1.5 0v2.578L8.983 3.223a.75.75 0 10-.738 1.306L10.94 6.05v4.639L6.921 8.37l.028-3.094a.75.75 0 00-.743-.756h-.007a.75.75 0 00-.75.743l-.02 2.246-2.232-1.29a.75.75 0 10-.75 1.3l2.232 1.289-1.935 1.14a.75.75 0 00.762 1.293L6.172 9.67l4.017 2.32-4.018 2.319-2.665-1.572a.75.75 0 00-.762 1.292L4.68 15.17l-2.233 1.29a.75.75 0 00.75 1.299l2.232-1.289.02 2.246a.75.75 0 00.75.744h.007a.75.75 0 00.743-.757l-.028-3.094 4.018-2.32v4.639L8.245 19.45a.75.75 0 00.739 1.306l1.955-1.105v2.578a.75.75 0 001.5 0V19.65l1.956 1.105a.747.747 0 001.022-.284.75.75 0 00-.284-1.022l-2.694-1.522v-4.639l4.018 2.32-.029 3.094a.75.75 0 101.5.014l.02-2.247 2.234 1.29a.747.747 0 001.024-.275.75.75 0 00-.275-1.025z"
			/>
		</svg>
	)
}
