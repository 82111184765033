import React from 'react'

export const SvgFog: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle: string }
> = ({ suffix, customTitle, ...props }) => {
	const idPrefix = 'svg-fog' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle}</title>
			<defs>
				<linearGradient id="svg-fog-a" x1="-30.939%" x2="139.74%" y1="2.487%" y2="93.773%">
					<stop offset="0%" stopColor="#FFF" />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
				</linearGradient>
			</defs>
			<g fill="none">
				<path
					fill="url(#svg-fog-a)"
					d="M21.108 10.222c-.045-2.508-2.055-4.54-4.562-4.586A6.909 6.909 0 009.7.034 6.925 6.925 0 002.79 6.02a4.689 4.689 0 00-2.394 6.167 4.682 4.682 0 004.292 2.8h11.859c2.575-.067 4.63-2.19 4.562-4.765z"
					transform="translate(0 2)"
				/>
				<path
					d="M23.25 19.983c-.685 0-1.032.363-1.337.683-.274.286-.49.512-.946.512-.457 0-.673-.226-.946-.512-.306-.32-.653-.683-1.337-.683-.686 0-1.032.363-1.338.683-.273.286-.489.512-.946.512-.456 0-.672-.226-.946-.512-.305-.32-.652-.683-1.337-.683-.685 0-1.031.363-1.337.683-.273.286-.489.512-.946.512-.456 0-.672-.226-.945-.512-.306-.32-.653-.683-1.338-.683s-1.031.363-1.337.683c-.273.286-.489.512-.946.512-.456 0-.672-.226-.945-.512-.306-.32-.653-.683-1.338-.683-.154 0-.28.134-.28.299 0 .165.126.299.28.299.457 0 .673.226.946.512.306.32.652.683 1.337.683.685 0 1.032-.363 1.338-.683.273-.286.489-.512.945-.512.457 0 .673.226.946.512.306.32.653.683 1.337.683.685 0 1.032-.363 1.338-.683.273-.286.489-.512.945-.512.457 0 .673.226.946.512.306.32.652.683 1.337.683.685 0 1.032-.363 1.338-.683.273-.286.489-.512.946-.512.456 0 .672.226.945.512.306.32.653.683 1.338.683s1.031-.363 1.337-.683c.273-.286.49-.512.946-.512.154 0 .28-.134.28-.3 0-.164-.126-.298-.28-.298zm0-3.865c-.685 0-1.032.362-1.337.683-.274.286-.49.512-.946.512-.457 0-.673-.226-.946-.512-.306-.32-.653-.683-1.337-.683-.686 0-1.032.362-1.338.683-.273.286-.489.512-.946.512-.456 0-.672-.226-.946-.512-.305-.32-.652-.683-1.337-.683-.685 0-1.031.362-1.337.683-.273.286-.489.512-.946.512-.456 0-.672-.226-.945-.512-.306-.32-.653-.683-1.338-.683s-1.031.362-1.337.683c-.273.286-.489.512-.946.512-.456 0-.672-.226-.945-.512-.306-.32-.653-.683-1.338-.683-.154 0-.28.133-.28.298 0 .165.126.3.28.3.457 0 .673.225.946.511.306.32.652.683 1.337.683.685 0 1.032-.362 1.338-.682.273-.287.489-.513.945-.513.457 0 .673.226.946.513.306.32.653.682 1.337.682.685 0 1.032-.362 1.338-.683.273-.286.489-.512.945-.512.457 0 .673.226.946.513.306.32.652.682 1.337.682.685 0 1.032-.362 1.338-.682.273-.287.489-.513.946-.513.456 0 .672.226.945.513.306.32.653.682 1.338.682s1.031-.362 1.337-.682c.273-.287.49-.513.946-.513.154 0 .28-.134.28-.299 0-.165-.126-.298-.28-.298z"
					fill="#FFF"
					stroke="#FFF"
					strokeWidth={0.588}
				/>
			</g>
		</svg>
	)
}
