import React from 'react'
import styled from 'styled-components'
import { useLocationContext } from 'providers/location-provider/location-provider'
import { Language } from 'generated/sdk'

const TITLE_TEXT = {
	[Language.Pt]: 'Fechar',
	[Language.En]: 'Close',
}

const SvgClosePath = styled.path`
	stroke: ${props => props.theme.color.primary};
`

export const SvgClose: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle?: string }
> = ({ suffix, customTitle, ...props }) => {
	const { language } = useLocationContext()
	const idPrefix = 'svg-close-large' + suffix

	return (
		<svg width={24} height={24} fill="none" role="img" aria-labelledby={`${idPrefix}-title`} {...props}>
			<title id={`${idPrefix}-title`}>{customTitle || TITLE_TEXT[language]}</title>
			<SvgClosePath
				d="M5.038 4.764L18.962 18.69m-13.456-.011L19.431 4.753"
				strokeWidth={1.5}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}
