import React from 'react'

export const SvgSun: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle: string }
> = ({ suffix, customTitle, ...props }) => {
	const idPrefix = 'svg-sun' + suffix

	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle}</title>
			<defs>
				<linearGradient id="svg-sun-a" x1="124.776%" x2="37.129%" y1="107.53%" y2="37.243%">
					<stop offset="0%" stopColor="#FFBE00" />
					<stop offset="100%" stopColor="#FFB700" />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd" transform="translate(2 2)">
				<circle cx={10} cy={10} r={5.455} fill="url(#svg-sun-a)" />
				<path
					fill="#FFB900"
					fillRule="nonzero"
					stroke="#FFB700"
					strokeWidth={0.5}
					d="M9.99 16.678a.682.682 0 00-.683.682v1.939a.682.682 0 001.364 0V17.36a.682.682 0 00-.682-.682zm0-13.375a.682.682 0 00.681-.682V.682a.682.682 0 10-1.364 0v1.939c0 .376.306.682.682.682zM4.297 14.719l-1.371 1.37a.682.682 0 10.964.965l1.371-1.37a.682.682 0 10-.964-.965zM15.2 5.46a.68.68 0 00.483-.199l1.37-1.37a.682.682 0 00-.963-.965l-1.371 1.37a.682.682 0 00.482 1.165zM3.302 9.99a.682.682 0 00-.682-.681H.682a.682.682 0 000 1.363H2.62a.682.682 0 00.682-.682zm15.997-.681h-1.94a.682.682 0 100 1.363h1.94a.682.682 0 100-1.363zM4.297 5.261a.68.68 0 00.964 0 .682.682 0 000-.964l-1.37-1.37a.682.682 0 10-.965.963l1.371 1.371zm11.387 9.459a.682.682 0 00-.965.963l1.37 1.37a.68.68 0 00.965 0 .682.682 0 000-.963l-1.37-1.37z"
				/>
			</g>
		</svg>
	)
}
