import React from 'react'
import styled from 'styled-components'

const SvgFacebookPath = styled.path`
	fill: ${props => props.theme.color.primary};
`

export const SvgFacebook: React.FC<JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string }> = ({
	suffix,
	...props
}) => {
	const idPrefix = 'svg-facebook' + suffix

	return (
		<svg width={24} height={24} aria-labelledby={`${idPrefix}-title`} role="img" fill="none" {...props}>
			<title id={`${idPrefix}-title`}>Facebook</title>
			<g clipPath={`url(#${idPrefix}-clip0)`}>
				<SvgFacebookPath d="M6.415 12.782h2.821v10.83c0 .215.186.388.415.388h4.783c.23 0 .415-.173.415-.387v-10.78h3.243c.211 0 .389-.147.413-.342l.492-3.988a.37.37 0 00-.102-.302.43.43 0 00-.31-.13H14.85V5.574c0-.754.434-1.136 1.293-1.136h2.442c.23 0 .415-.173.415-.387V.39c0-.214-.186-.387-.415-.387h-3.366A3.551 3.551 0 0015.065 0c-.584 0-2.614.107-4.218 1.483-1.776 1.524-1.53 3.35-1.47 3.666v2.923H6.415c-.23 0-.415.173-.415.387v3.936c0 .214.186.387.415.387z" />
			</g>
			<defs>
				<clipPath id={`${idPrefix}-clip0`}>
					<SvgFacebookPath d="M0 0h24v24H0z" />
				</clipPath>
			</defs>
		</svg>
	)
}
