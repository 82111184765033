import React from 'react'

export const SvgHail: React.FC<
	JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & { suffix: string; customTitle: string }
> = ({ suffix, customTitle, ...props }) => {
	const idPrefix = 'svg-hail' + suffix
	return (
		<svg role="img" aria-labelledby={`${idPrefix}-title`} width={24} height={24} viewBox="0 0 24 24" {...props}>
			<title id={`${idPrefix}-title`}>{customTitle}</title>
			<defs>
				<linearGradient id="svg-hail-a" x1="-30.939%" x2="139.74%" y1="2.487%" y2="93.773%">
					<stop offset="0%" stopColor="#FFF" />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
					<stop offset="100%" stopColor="#FFF" stopOpacity={0.5} />
				</linearGradient>
			</defs>
			<g fill="none">
				<path
					fill="url(#svg-hail-a)"
					d="M19.936 9.654c-.043-2.368-1.942-4.288-4.31-4.331A6.525 6.525 0 009.162.033a6.54 6.54 0 00-6.528 5.653A4.428 4.428 0 00.373 11.51a4.422 4.422 0 004.053 2.645h11.2c2.432-.064 4.374-2.07 4.31-4.501z"
					transform="translate(2 2)"
				/>
				<path
					d="M20.889 17.556a1.12 1.12 0 00-.786.325 1.12 1.12 0 00-.325.786c0 .292.118.578.325.786.207.206.493.325.786.325.292 0 .58-.119.786-.325a1.12 1.12 0 00.325-.786c0-.293-.119-.58-.325-.786a1.12 1.12 0 00-.786-.325zm-16.667 0a1.12 1.12 0 00-.786.325 1.12 1.12 0 00-.325.786c0 .292.119.578.325.786.207.206.494.325.786.325.293 0 .58-.119.786-.325a1.12 1.12 0 00.325-.786c0-.293-.118-.58-.325-.786a1.12 1.12 0 00-.786-.325zm12.5 1.111a1.12 1.12 0 00-.786.325 1.12 1.12 0 00-.325.786c0 .292.119.579.325.786.208.206.494.325.786.325.293 0 .579-.119.786-.325a1.12 1.12 0 00.325-.786 1.12 1.12 0 00-.325-.786 1.12 1.12 0 00-.786-.325zm-8.333 0c-.293 0-.58.118-.786.325a1.12 1.12 0 00-.325.786c0 .292.118.579.325.786.207.206.493.325.786.325.292 0 .578-.119.786-.325a1.12 1.12 0 00.325-.786 1.12 1.12 0 00-.325-.786 1.12 1.12 0 00-.786-.325zm4.167-1.111a1.12 1.12 0 00-.786.325 1.12 1.12 0 00-.326.786c0 .292.119.578.326.786.207.206.493.325.786.325.292 0 .578-.119.785-.325a1.12 1.12 0 00.326-.786c0-.293-.119-.58-.326-.786a1.12 1.12 0 00-.785-.325z"
					fill="#FFF"
				/>
			</g>
		</svg>
	)
}
