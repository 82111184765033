import React from 'react'
import styled from 'styled-components'
import { rgbaWithHex, transition } from 'theme/utils'
import { removeTrailingSlash } from 'utils/url'

export type Language = {
	href: string
	label: string
	isCurrent: boolean
}

export type LanguageChooserProps = {
	languages: Language[]
}

export const LanguageChooserLink = styled.a`
	display: inline-block;
	font-family: ${props => props.theme.fonts.primary};
	font-size: 16px;
	font-weight: 500;
	line-height: 1;
	color: ${props => props.theme.text.primary};
	text-transform: uppercase;
	cursor: pointer;
	transition: ${transition('color')};

	&::after {
		display: block;
		height: 0;
		overflow: hidden;
		font-weight: bold;
		visibility: hidden;
		content: attr(title);
	}

	&:hover,
	&:focus,
	&:active {
		${props => rgbaWithHex(props.theme.text.primary, 0.65)}
		text-decoration: none;
	}
`

const LanguageChooserComponent = ({ languages, ...props }: LanguageChooserProps) => {
	const notCurrentLangs = languages.filter(lang => lang.isCurrent !== true)

	return (
		<>
			{notCurrentLangs.map(lang => (
				<LanguageChooserLink href={removeTrailingSlash(lang.href)} key={lang.label} {...props} title={lang.label}>
					{lang.label}
				</LanguageChooserLink>
			))}
		</>
	)
}

export const LanguageChooser = React.memo(LanguageChooserComponent)
