'use client'

import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useRouter } from 'next/navigation'
import { useLocationContext } from 'providers/location-provider/location-provider'
import { darkTheme } from 'theme/theme'
import {
	rgbaWithHex,
	borderRgbaWithHex,
	positionAbsoluteAndStretch,
	bgPortoPattern,
	ellipses,
	transition,
} from 'theme/utils'
import { SearchInput } from 'components/search-input/search-input'
import { ActiveLink } from 'components/active-link/active-link'
import { LinkItem } from 'components/primary-navigation/primary-navigation'
import { SecondaryLinkItem } from 'components/secondary-navigation/secondary-navigation'
import { SvgArrowDown } from 'components/svgs/svg-arrow-down'
import { splitArrayIntoColumns } from 'utils/arrays'
import { Language } from 'generated/sdk'
import { pushNewRoute } from 'utils/router'

type MenuBurgerProps = {
	primaryLinks: LinkItem[]
	secondaryLinksTitle: string
	secondaryLinks: SecondaryLinkItem[]
}

const MenuBurgerWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	padding: 47px 0 0;

	&::before {
		${positionAbsoluteAndStretch}
		content: ' ';
		${bgPortoPattern}
	}
`

const MenuBurgerBody = styled.div`
	height: 100%;
	padding: 10px 16px 0;
	overflow-y: scroll;
	${props => rgbaWithHex(props.theme.colors.twilightBlue, 1, 'background-color')}
`

const MenuBurgerSectionTitle = styled.p`
	margin: 24px 0 10px;
	font-size: 16px;
	font-weight: 700;
	line-height: 19px;
	color: ${props => props.theme.color.primary};
	text-transform: uppercase;
`

const MenuBurgerPrimaryNavigationNav = styled.nav`
	display: flex;
	padding-top: 26px;
	padding-bottom: 18px;
	${props => borderRgbaWithHex('1px solid', props.theme.colors.lightPeriwinkle, 0.5, 'border-bottom')}
`

const MenuBurgerPrimaryNavigationNavList = styled.ul`
	width: 50%;
	padding: 0;
	margin: 0;
	list-style: none;
`

const MenuBurgerPrimaryNavigationNavItem = styled.li`
	position: relative;
	font-size: 0;

	a {
		display: block;
		max-width: calc(100% - 30px);
		padding: 6px 0;
		font-size: 16px;
		font-weight: 700;
		line-height: 19px;
		color: ${props => props.theme.color.primary};
		text-decoration: none;
		${ellipses}

		&:hover,
		&:focus,
		&:active {
			color: ${props => props.theme.color.secondary};
			text-decoration: none;
		}
	}
`

const MenuBurgerSecondaryNavigationNavListWrapper = styled.nav`
	display: flex;
`

// Secondary navigation
const MenuBurgerSecondaryNavigationNavList = styled.ul`
	display: flex;
	flex-direction: column;
	width: 50%;
	padding: 0;
	margin: 0;
	list-style: none;
`

const MenuBurgerSecondaryNavigationNavItem = styled.li`
	position: relative;
	font-size: 0;

	> div {
		display: flex;
		align-items: center;
	}

	a {
		position: relative;
		display: block;
		max-width: calc(100% - 30px);
		padding: 6px 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 19px;
		color: ${props => props.theme.color.primary};
		${ellipses}

		&:hover,
		&:focus,
		&:active {
			color: ${props => props.theme.color.secondary};
			text-decoration: none;
		}
	}
`

const MenuBurgerSecondaryNavigationNavItemButton = styled.button`
	padding: 5px 6px 8px 4px;
	background: transparent;
	border: none;

	&:focus {
		outline: none;
	}

	svg {
		opacity: 0.7;
	}
`

// Sub navigation menu styles
const MenuBurgerSecondaryNavigationSubNavList = styled.ul<{ isOpen: boolean; innerHeight: number }>`
	display: block;
	max-height: ${props => (props.isOpen ? props.innerHeight + 'px' : 0)};
	padding: 0;
	margin: 0;
	overflow: hidden;
	list-style: none;
	transition: ${transition('all')};
`

const MenuBurgerSecondaryNavigationSubNavItem = styled.li`
	display: block;

	a {
		display: block;
		padding: 6px 0;
		overflow: hidden;
		font-size: 14px;
		font-weight: 500;
		text-overflow: ellipsis;
		text-transform: none;
		white-space: nowrap;
		opacity: 0.7;
	}
`

const PrimaryNavigationListItem = (item: LinkItem, index: number) => (
	<MenuBurgerPrimaryNavigationNavItem key={`${item.label}-${index}`}>
		<ActiveLink href={item.href} title={item.label}>
			{item.label}
		</ActiveLink>
	</MenuBurgerPrimaryNavigationNavItem>
)

const subNavigationListItem = (item: SecondaryLinkItem, index: number) => (
	<MenuBurgerSecondaryNavigationSubNavItem key={`${item.label}-${index}`}>
		<ActiveLink href={item.href} title={item.label}>
			{item.label}
		</ActiveLink>
	</MenuBurgerSecondaryNavigationSubNavItem>
)

const SecondaryNavigationListItem = (item: SecondaryLinkItem, index: number) => {
	const subLinks = item.subLinks ? item.subLinks : []
	const hasSubLinks = subLinks.length > 0
	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

	return (
		<MenuBurgerSecondaryNavigationNavItem key={`${item.label}-${index}`}>
			<div>
				<ActiveLink href={item.href} title={item.label} {...(hasSubLinks && { 'aria-haspopup': 'true' })}>
					{item.label}
				</ActiveLink>
				{hasSubLinks && (
					<MenuBurgerSecondaryNavigationNavItemButton onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}>
						<SvgArrowDown suffix={`${item.label}-${index}`} rotate={isSubMenuOpen ? 180 : 0} />
					</MenuBurgerSecondaryNavigationNavItemButton>
				)}
			</div>

			{hasSubLinks && (
				<MenuBurgerSecondaryNavigationSubNavList
					aria-hidden="true"
					aria-expanded="false"
					aria-label={`${item.label} submenu`}
					isOpen={isSubMenuOpen}
					innerHeight={subLinks.length * 31}>
					{subLinks.map(subNavigationListItem)}
				</MenuBurgerSecondaryNavigationSubNavList>
			)}
		</MenuBurgerSecondaryNavigationNavItem>
	)
}

const MenuBurgerComponent = ({ primaryLinks, secondaryLinksTitle, secondaryLinks }: MenuBurgerProps) => {
	const { language } = useLocationContext()
	const router = useRouter()
	const searchPath = language === Language.En ? '/en/search/' : '/pt/pesquisa/'

	const handleSearch = (searchTerm: string) => {
		pushNewRoute(router, searchPath, { searchQuery: searchTerm })
	}

	const primaryLinksColumns = splitArrayIntoColumns(primaryLinks, 2)
	const secondaryLinksColumns = splitArrayIntoColumns(secondaryLinks, 2)

	return (
		<MenuBurgerWrapper>
			<ThemeProvider theme={darkTheme}>
				<MenuBurgerBody>
					<SearchInput handleSearch={handleSearch} compressed />
					<MenuBurgerPrimaryNavigationNav>
						{primaryLinksColumns.map((column, index) => (
							<MenuBurgerPrimaryNavigationNavList key={index}>
								{column.map(PrimaryNavigationListItem)}
							</MenuBurgerPrimaryNavigationNavList>
						))}
					</MenuBurgerPrimaryNavigationNav>
					<MenuBurgerSectionTitle>{secondaryLinksTitle}</MenuBurgerSectionTitle>
					<MenuBurgerSecondaryNavigationNavListWrapper>
						{secondaryLinksColumns.map((column, index) => (
							<MenuBurgerSecondaryNavigationNavList key={index}>
								{column.map(SecondaryNavigationListItem)}
							</MenuBurgerSecondaryNavigationNavList>
						))}
					</MenuBurgerSecondaryNavigationNavListWrapper>
				</MenuBurgerBody>
			</ThemeProvider>
		</MenuBurgerWrapper>
	)
}

export const MenuBurger = React.memo(MenuBurgerComponent)
